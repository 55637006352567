import React from 'react'
import { IMAGES } from '../constants'

const Loader = () => {
    return (
        <div className="loader">
            <video autoPlay loop muted playsInline style={{ width: "150px", height: "150px" }}>
                <source src={IMAGES.loading} type="video/webm" />
                <source src={IMAGES.loading} type="video/mp4" />
            </video>
        </div>
    )
}

export default Loader